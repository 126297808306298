import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FormNuovoPreventivoComponent } from './components/form-nuovo-preventivo/form-nuovo-preventivo.component';
import { ListaPreventiviComponent } from './components/lista-preventivi/lista-preventivi.component';
import { MascheraRicercaComponent } from './components/maschera-ricerca/maschera-ricerca.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './auth/authGuard';


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuardService]},
  { path: 'login', component: LoginComponent },
  { path: 'nuovo-preventivo', component: FormNuovoPreventivoComponent, canActivate: [AuthGuardService] },
  { path: 'visualizza-preventivi', component: ListaPreventiviComponent, canActivate: [AuthGuardService]},
  // { path: 'ricerca', component: MascheraRicercaComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
