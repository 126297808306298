import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import {MatTableModule} from '@angular/material/table';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormNuovoPreventivoComponent } from './components/form-nuovo-preventivo/form-nuovo-preventivo.component';
import { HomeComponent } from './home/home.component';
import { ListaPreventiviComponent } from './components/lista-preventivi/lista-preventivi.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule,MatOptionModule, MatSelectModule, MatIconModule, MatNativeDateModule} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormModificaPreventivoComponent } from './components/form-modifica-preventivo/form-modifica-preventivo.component';
import {MatDialogModule} from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { registerLocaleData } from '@angular/common';
import localeItIt from '@angular/common/locales/it';
import { LoaderComponent } from './components/loader/loader.component';
import { MascheraRicercaComponent } from './components/maschera-ricerca/maschera-ricerca.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './auth/authGuard';
import { AuthService } from './auth/authService';
import { JwtModuleOptions, JwtModule } from '@auth0/angular-jwt';
import { TokenInterceptor } from './auth/token-interceptor';


registerLocaleData(localeItIt);
export function tokenGetter() {
  return sessionStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    FormNuovoPreventivoComponent,
    HomeComponent,
    ListaPreventiviComponent,
    FormModificaPreventivoComponent,
    LoaderComponent,
    MascheraRicercaComponent,
    LoginComponent,
  ],


  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: ["mbk.albedo.it"],
        // blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatDialogModule,
    HttpClientModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    MatDatepickerModule,
     {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FormModificaPreventivoComponent,
    MascheraRicercaComponent
  ]
})
export class AppModule { }
